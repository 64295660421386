// Mood Board Color
export const BG_PRIMARY = '#D9B79A'; // Warna Chreme Muda
export const BG_SECONDARY = '#F2E6D8'; // Warna Chreme Yang Lebih Tua
export const BG_ALTERNATIVE = '#895c36'; // Warna Ketiga Coklat Tua
// Text Color
export const TEXT_PRIMARY = '#100F0D'; // Warna Hitam Body
export const TEXT_SECONDARY = '#000'; // Warana Coklat Tua sama dengan BG_ALTERNATIVE
export const TEXT_ALTERNATIVE = '#B47461'; // Alternative Color
// Music & Navigation Color
export const NAVIGATION_COLOR = BG_ALTERNATIVE; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
// Drawer Color
export const DRAWER_COLOR = 'rgb(116 84 63)';
export const BORDER_COLOR = '#F2E6D8';
export const FOOTER_COLOR = '#7e6c696e';
// Default Button Color
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};